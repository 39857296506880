var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-shrink-8 flex-grow-1 px-sm-0 pb-5"},[_c('div',{staticClass:"container-fluid mt-5"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"row mb-5 pb-5 mt-5 pt-5"},[_c('div',{staticClass:"col-md-8"},[_c('h2',[_vm._v("Contact Us")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-7"},[_c('form',{staticClass:"text-left mw-1000",attrs:{"id":"contactUsForm","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.sendContactUsEmail.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.firstName.$model),expression:"$v.form.firstName.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.firstName.$error && _vm.$v.form.firstName.$dirty,
                        'is-invalid': _vm.$v.form.firstName.$error
                      },attrs:{"type":"text","id":"firstName","name":"firstName","placeholder":"First Name","maxlength":"64"},domProps:{"value":(_vm.$v.form.firstName.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.firstName, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.lastName.$model),expression:"$v.form.lastName.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.lastName.$error && _vm.$v.form.lastName.$dirty,
                        'is-invalid': _vm.$v.form.lastName.$error
                      },attrs:{"type":"text","id":"lastName","name":"lastName","placeholder":"Last Name","maxlength":"64"},domProps:{"value":(_vm.$v.form.lastName.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.lastName, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.email.$error && _vm.$v.form.email.$dirty,
                        'is-invalid': _vm.$v.form.email.$error
                      },attrs:{"type":"email","id":"email","name":"email","placeholder":"Email","maxlength":"64"},domProps:{"value":(_vm.$v.form.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.email, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.phone.$model),expression:"$v.form.phone.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.phone.$error && _vm.$v.form.phone.$dirty,
                        'is-invalid': _vm.$v.form.phone.$error
                      },attrs:{"type":"phone","id":"phone","name":"phone","placeholder":"Phone Number","maxlength":"16"},domProps:{"value":(_vm.$v.form.phone.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.phone, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.company.$model),expression:"$v.form.company.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.company.$error && _vm.$v.form.company.$dirty,
                        'is-invalid': _vm.$v.form.company.$error
                      },attrs:{"type":"text","id":"company","name":"company","placeholder":"Company","maxlength":"64"},domProps:{"value":(_vm.$v.form.company.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.company, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.jobTitle.$model),expression:"$v.form.jobTitle.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.jobTitle.$error && _vm.$v.form.jobTitle.$dirty,
                        'is-invalid': _vm.$v.form.jobTitle.$error
                      },attrs:{"type":"text","id":"jobTitle","name":"jobTitle","placeholder":"Job Title","maxlength":"64"},domProps:{"value":(_vm.$v.form.jobTitle.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.jobTitle, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.message.$model),expression:"$v.form.message.$model"}],staticClass:"form-control",class:{
                        'is-valid': !_vm.$v.form.message.$error && _vm.$v.form.message.$dirty,
                        'is-invalid': _vm.$v.form.message.$error
                      },attrs:{"type":"text","id":"message","name":"message","placeholder":"Message","maxlength":"1024","rows":"4"},domProps:{"value":(_vm.$v.form.message.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.message, "$model", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","disabled":_vm.$v.form.$invalid}},[_vm._v("Send")])])])])]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('br'),_c('br'),_c('h5',[_vm._v("CORPORATE HQ")]),_vm._v(" 41 High Street"),_c('br'),_vm._v(" North Andover, MA 01845"),_c('br'),_c('br'),_c('b',[_vm._v("Phone:")]),_vm._v("  978 684-7580"),_c('br'),_c('b',[_vm._v("Fax:")]),_vm._v("  978 684-7585"),_c('br'),_c('b',[_vm._v("Email:")]),_vm._v("  "),_c('a',{attrs:{"href":"mailto:privacychoice@semcasting.com"}},[_vm._v("privacychoice@semcasting.com")]),_c('br'),_c('br'),_vm._v(" PRIVACY"),_c('br'),_c('br'),_vm._v(" We will use your information to communicate with you about this general contact form and other solutions, events and related resources that may be of interest to you. You may opt-out at any time using the unsubscribe in messages you receive from us. For more information, please see our "),_c('a',{attrs:{"href":"https://www.semcasting.com/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(". ")])
}]

export { render, staticRenderFns }