<template>
  <div class="flex-shrink-8 flex-grow-1 px-sm-0 pb-5">
    <div class="container-fluid mt-5">
      <div class="container mx-auto">
        <div class="row mb-5 pb-5 mt-5 pt-5"> 

          <!-- Left col -->
          <div class="col-md-8"> 
            <h2>Contact Us</h2>

              <div class="card">
                <div class="card-body p-7">
                  <form id="contactUsForm" class="text-left mw-1000" autocomplete="off" @submit.prevent="sendContactUsEmail">
                    
                    <!-- Name row -->
                    <div class="form-group">
                      <input 
                        type="text" 
                        id="firstName" 
                        name="firstName" 
                        placeholder="First Name" 
                        v-model="$v.form.firstName.$model" 
                        maxlength="64"
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.firstName.$error && $v.form.firstName.$dirty,
                          'is-invalid': $v.form.firstName.$error
                        }"
                      />
                    </div>

                    <div class="form-group">
                      <input 
                        type="text" 
                        id="lastName" 
                        name="lastName" 
                        placeholder="Last Name" 
                        v-model="$v.form.lastName.$model" 
                        maxlength="64"
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.lastName.$error && $v.form.lastName.$dirty,
                          'is-invalid': $v.form.lastName.$error
                        }"
                      />
                    </div>
                    <!-- End name row -->

                    <!-- Email row -->
                    <div class="form-group">
                      <label for="email" class="sr-only">Email</label>
                      <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        placeholder="Email" 
                        v-model="$v.form.email.$model" 
                        maxlength="64"
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                          'is-invalid': $v.form.email.$error
                        }"
                      />
                    </div>

                    <!-- Phone row -->
                    <div class="form-group">
                      <input 
                        type="phone" 
                        id="phone" 
                        name="phone" 
                        placeholder="Phone Number" 
                        v-model="$v.form.phone.$model"
                        maxlength="16" 
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.phone.$error && $v.form.phone.$dirty,
                          'is-invalid': $v.form.phone.$error
                        }"
                      />
                    </div>

                    <!-- Company -->
                    <div class="form-group">
                      <input 
                        type="text"
                        id="company" 
                        name="company" 
                        placeholder="Company" 
                        v-model="$v.form.company.$model"
                        maxlength="64" 
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.company.$error && $v.form.company.$dirty,
                          'is-invalid': $v.form.company.$error
                        }"
                      />
                    </div>

                    <!-- Job Title -->
                    <div class="form-group">
                      <input 
                        type="text" 
                        id="jobTitle" 
                        name="jobTitle" 
                        placeholder="Job Title" 
                        v-model="$v.form.jobTitle.$model"
                        maxlength="64" 
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.jobTitle.$error && $v.form.jobTitle.$dirty,
                          'is-invalid': $v.form.jobTitle.$error
                        }"
                      />
                    </div>

                    <!-- Message -->
                    <div class="form-group">
                      <textarea 
                        type="text" 
                        id="message" 
                        name="message" 
                        placeholder="Message" 
                        v-model="$v.form.message.$model"
                        maxlength="1024"
                        rows="4" 
                        class="form-control"
                        :class="{
                          'is-valid': !$v.form.message.$error && $v.form.message.$dirty,
                          'is-invalid': $v.form.message.$error
                        }"
                      />
                    </div>

                    <!-- Send button row -->
                    <button type="submit" 
                            :disabled="$v.form.$invalid" 
                            class="btn btn-primary btn-block">Send</button>
                  </form>

                </div>
              </div>
          </div>

          <!-- Right col -->
          <div class="col-md-4">
            <br><br>
            <h5>CORPORATE HQ</h5>
            41 High Street<br>
            North Andover, MA 01845<br>
            <br>
            <b>Phone:</b>&nbsp;&nbsp;978 684-7580<br>
            <b>Fax:</b>&nbsp;&nbsp;978 684-7585<br>
            <b>Email:</b>&nbsp;&nbsp;<a href="mailto:privacychoice@semcasting.com">privacychoice@semcasting.com</a>
            <br><br>
            PRIVACY<br>
            <br>
            We will use your information to communicate with you about this general contact form and 
            other solutions, events and related resources that may be of interest to you. You may opt-out 
            at any time using the unsubscribe in messages you receive from us. For more information, 
            please see our <a href="https://www.semcasting.com/privacy-policy/" target="_blank">Privacy Policy</a>.

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { email } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';
// import { sameAs } from 'vuelidate/lib/validators';
// import { not } from 'vuelidate/lib/validators';

export default {
  name: 'ContactUsView',

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        company: null,
        jobTitle: null,
        message: null
      }
    }
  },

  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { email, required },
      phone: { required },
      company: { required },
      jobTitle: { required },
      message: { required }
    }
  },

  methods: {
    sendContactUsEmail() {
      this.pcSpinnerShow();
      this.apiContactUs(this.form)
      .then(() => {
        this.pcModal("Your message has been sent")
        .then(() => { this.$router.push({ name: 'PersonalInformationRoute' }) })
      })
      .catch((err) => {
        this.pcModal("There was a problem sending your message").finally();
        console.log(err)
      })
      .finally(() => { 
        this.pcSpinnerHide() 
      })
    }
  }
}
</script>
